import { Header } from "@/components/format/Dialog";
import { ChartBar } from "lucide-react";
import { LessonProgressTable } from "@/lms/lesson/LessonProgressTable";
import { UnitsResponse } from "@/lib/pb/types";
import { ClassFilter } from "../classes/ClassFilter";

import { ScrollArea } from "@/components/ui/scroll-area";
import { useLesson } from "@/lib/store";

export function LessonProgressDialog ({ item }: { item: UnitsResponse }) {
  const lesson = useLesson();
  if(!lesson) return null;
  return (
    <div className="flex flex-col gap-2">
      <Header
        title={
          <div className="flex text-primary items-center gap-2">
            <ChartBar className="h-6 w-6 me-2 text-4xl" /> Lesson Progress:  <div className="text-foreground text-2xl">{lesson.index}. {lesson.name}</div>
            <ClassFilter className="text-sm ms-10" />
          </div>
        }
      />
      <ScrollArea className="h-[90dvh]">
        <div className="flex flex-col ">
         <LessonProgressTable lesson={lesson} />
        </div>
      </ScrollArea>
    </div>
  );
}
