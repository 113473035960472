import { queryOptions } from "@tanstack/react-query";
import {
  useAddMutation,
  useDeleteMutation,
  useEditMutation,
} from "./mutations";
import { SectionProgressRecord } from "./types";
import { getFullList, getFullMap, getGroupList } from "@/lib/pb";
import { DEFAULT_STALETIME } from "./lms";

export const useAddSectionProgressMutation = (enrollmentId: string) =>
  useAddMutation<SectionProgressRecord, SectionProgressRecord>(
    "section_progress",
    [sectionProgressListQuery(enrollmentId).queryKey],
  );

export const useEditSectionProgressMutation = (enrollmentId: string) =>
  useEditMutation<SectionProgressRecord, SectionProgressRecord>(
    "section_progress",
    [sectionProgressListQuery(enrollmentId).queryKey],
  );

export const sectionProgressListQuery = (enrollmentId: string) =>
  queryOptions({
    queryKey: ["section_progress", "list", { enrollmentId }],
    queryFn: () =>
      getFullMap<SectionProgressRecord>(
        "section_progress",
        {
          filter: `enrollmentId="${enrollmentId}"`,
          sort: "updated",
          fields: "id, enrollmentId, sectionId, complete", //, expand.sectionId.lessonId",
          //     expand:"sectionId"
        },
        "sectionId",
      ),
    staleTime: DEFAULT_STALETIME,
    // refetchOnWindowFocus: false,
    // refetchOnReconnect: false,
    // refetchOnMount: "always",
    meta: { errorMessage: "Failed to fetch section progress" },
    enabled: !!enrollmentId,
  });

export const sectionProgressAdminListQuery = (
  lessons: string[],
  classId: string,
) => {
  const lessonFilters = lessons
    .map((id) => `sectionId.lessonId="${id}"`)
    .join("||");

  const filter = `(${lessonFilters})${classId ? `&& enrollmentId.classId="${classId}"` : ""}`;

  return queryOptions({
    queryKey: ["section_progress", "list", { filter }, "admin"],
    queryFn: () =>
      getGroupList<SectionProgressRecord>(
        "section_progress",
        {
          filter,
          sort: "updated",
          fields: "id, enrollmentId, sectionId, complete", //, expand.sectionId.lessonId",
          //     expand:"sectionId"
        },
        "enrollmentId",
      ),
    staleTime: DEFAULT_STALETIME,
    // refetchOnWindowFocus: false,
    // refetchOnReconnect: false,
    // refetchOnMount: "always",
    meta: { errorMessage: "Failed to fetch admin section progress" },
    // enabled: !!enrollmentId,
  });
};
