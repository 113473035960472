import { FilterNav, fuzzyFilter } from "./FilterTable";
import { BaseTable, TableProps } from "./Table";
import {
  Table as TanstackTable,
  PaginationState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  ColumnFiltersState,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
} from "@tanstack/react-table";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
// import {
//   Pagination,
//   PaginationItem,
//   PaginationContent,
// } from "@/components/ui/pagination";

import { useState } from "react";

import { ChevronLeft, ChevronRight } from "lucide-react";

export function PaginationTable<T>({
  data = [],
  columns,
  sortEnabled = false,
  isPending = false,
  refetch,
  initialPageSize = 30,
  columnFilters: intialFilters,
}: TableProps<T>) {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    intialFilters ? intialFilters : [],
  );

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: initialPageSize,
  });

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    state: {
      pagination,
      columnFilters,
    },
    enableMultiRowSelection: false,
    onColumnFiltersChange: setColumnFilters,
    //debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: !sortEnabled ? undefined : getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    // autoResetPageIndex: false, // turn off page index reset when sorting or filtering

    getFacetedRowModel: getFacetedRowModel(), // client-side faceting
    getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
    getFacetedMinMaxValues: getFacetedMinMaxValues(), // generate min/max values for range filter
  });

  return (
    <div className="grid gap-2">
      {!isPending && <FilterNav table={table} refetch={refetch} />}
      <BaseTable
        table={table}
        sortEnabled={sortEnabled}
        isPending={isPending}
      />
      {!isPending && <PaginationNav table={table} />}
    </div>
  );
}

function PaginationNav({ table }: { table: TanstackTable<any> }) {
  return (
    <div className="flex items-center gap-2 justify-between">
      <Select
        defaultValue={table.getState().pagination.pageSize.toString()}
        onValueChange={(value) => {
          table.setPageSize(Number(value));
        }}
      >
        <SelectTrigger className="w-28 h-6 text-sm">
          <SelectValue placeholder="Select a page size" />
        </SelectTrigger>
        <SelectContent>
          {["10", "20", "30", "40", "50"].map((pageSize) => (
            <SelectItem key={pageSize} value={pageSize}>
              Show {pageSize}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <div className="flex w-[100px] items-center justify-center text-sm font-medium">
        Page {table.getState().pagination.pageIndex + 1} of{" "}
        {table.getPageCount()}
      </div>

      <PaginationButtons table={table} />
    </div>
  );
}

// <div>
//   Showing {table.getRowModel().rows.length.toLocaleString()} of{" "}
//   {table.getRowCount().toLocaleString()}
// </div>

function PaginationButtons({ table }: { table: TanstackTable<any> }) {
  if (!table || table.getPageCount() < 2) return;

  return (
    <div className="flex gap-2">
      <PageButton
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        <ChevronLeft className="h-4 w-4" />
      </PageButton>

      {Array.from({ length: table.getPageCount() }, (_, index) => (
        <PageButton
          key={index}
          onClick={() => table.setPageIndex(index)}
          active={index === table.getState().pagination.pageIndex}
        >
          {index + 1}{" "}
        </PageButton>
      ))}

      <PageButton
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        <ChevronRight className="h-4 w-4" />
      </PageButton>
    </div>
  );
}
//pagination debug
//<pre>{JSON.stringify(table.getState().pagination, null, 2)}</pre>
function PageButton({
  active = false,
  children,
  ...props
}: {
  active?: boolean;
  children: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <Button
      size="icon"
      className="w-6 h-6"
      variant={active ? "default" : "outline"}
      {...props}
    >
      {children}
    </Button>
  );
}
// function PageButton({
//   icon,
//   ...props
// }: {
//   icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
// } & React.ButtonHTMLAttributes<HTMLButtonElement>) {
//   return (
//     <Button size="icon" className="w-6 h-6" {...props}>
//       {createElement(icon, { className: "w-4 h-4" })}
//     </Button>
//   );
// }
// <PageButton
//   icon={ChevronsLeft}
//   onClick={() => table.firstPage()}
//   disabled={!table.getCanPreviousPage()}
// />
// <PageButton
//   icon={ChevronsRight}
//   onClick={() => table.lastPage()}
//   disabled={!table.getCanNextPage()}
// />

// <span className="flex items-center gap-1">
//   <div>Page</div>
//   <strong>
//     {table.getState().pagination.pageIndex + 1} of{" "}
//     {table.getPageCount().toLocaleString()}
//   </strong>
// </span>
// <span className="flex items-center gap-1">
//   <Input
//     type="number"
//     defaultValue={table.getState().pagination.pageIndex + 1}
//     onChange={(e) => {
//       const page = e.target.value ? Number(e.target.value) - 1 : 0;
//       table.setPageIndex(page);
//     }}
//     className=" rounded w-16"
//   />
// </span>
