import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { PaginationTable, fuzzySort, fuzzyFilter } from "@/components/table";
import { enrollmentsListQuery } from "@/lib/pb/lms";

import { ColumnDef } from "@tanstack/react-table";
import {
  EnrollmentsResponse,
  LessonsResponse,
  UnitsResponse,
} from "@/lib/pb/types";
import { format, formatRelative } from "date-fns";
import { RoleNames } from "@/lib/roles";
//import { useClassFilter } from "@/lib/store";
import { Avatar } from "@/components/format/Avatar";
import { DialogButton } from "@/components/format/Buttons";
import { ArrowLeftRight, Pencil, X } from "lucide-react";
import { UserEditForm } from "../user/UserAdminForms";
import { sectionProgressAdminListQuery } from "@/lib/pb/section-progress";
import { useClassFilter } from "@/lib/store";
import { arrayToMap, groupBy } from "@/lib/pb";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

//Each of these is an enrollment
export const USERS_COLUMNS: ColumnDef<EnrollmentsResponse>[] = [
  {
    accessorFn: (row) => `${row.expand.userId.name}`,
    id: "className", //why?? TODO??
    header: () => <span>Name</span>,
    cell: (info) => {
      const name = info.getValue() as string;
      const user = info.row.original.expand?.userId;
      return (
        <div className="flex gap-2 items-center">
          <Avatar user={user} />
          <div className="flex flex-col">
            <div className="me-auto">{name}</div>
            <div className="text-xs text-muted-foreground">{user?.email}</div>
          </div>
        </div>
      );
    },
    meta: { filterVariant: "text", filterName: "Name" },
    filterFn: fuzzyFilter, //or just define with the function
    sortingFn: fuzzySort, //sort by fuzzy rank (falls back to alphanumeric)
  },
  {
    accessorKey: "expand.userId.role",
    id: "role",
    header: () => "Role",
    cell: (info) => {
      const value = info.getValue() as keyof typeof RoleNames;
      return (
        <div>
          <div>{value}</div>
          <div className="text-xs">{RoleNames[value]}</div>
        </div>
      );
    },
    meta: { filterVariant: "multiselect", filterName: "Role" },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "expand.classId.name",
    header: () => "Class",
    cell: (info) => {
      const value = info.getValue();
      return value;
    },
  },
  {
    accessorKey: "expand.teamId.name",
    header: () => "Team",
    cell: (info) => {
      const value = info.getValue();
      return value;
    },
    meta: { filterVariant: "multiselect", filterName: "Team" },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  
  {
    accessorKey: "totalCompleted",
    id: "progress",
    header: () => "Progress (by Section)",
    cell: (info) => {
      const progress = info.row.original.progress;
      const sections = info.row.original.sections;
      //console.log(progress);
      //value ? value.size : 0;
      return (
        <div className="min-w-[100px] ">
          {/* <Progress value={10} /> */}
          <div className="flex">
            {sections.map((s) => (
              <TooltipProvider key={s.id}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div>
                      <div className="flex w-12 text-xs border justify-center">
                        <div>{s.index}</div>
                      </div>

                      <div className="flex w-12 text-xs border justify-center">
                        <div
                          className={`h-4 w-full ${progress?.get(s.id) ? "bg-success" : "bg-background"}`}
                        ></div>
                      </div>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{s.name}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))}
          </div>
        </div>
      );
    },
  },
  {
    accessorKey: "totalCompleted",
    header: () => "# Sections Completed",
    // cell: (info) => {
    //   const value = info.getValue();
    //   return value ? value.size : 0;
    // },
  },
];


export function LessonProgressTable({ lesson }: { lesson: LessonsResponse }) {
  const { children: sections } = lesson;
  const [data, setData] = useState<any>();
  const cls = useClassFilter();

  const filter = `courseId="${lesson.courseId}"&&classId.active=true${cls?.id ? `&&classId="${cls.id}"` : ""}`;
  const {
    data: enrollments,
    error,
    isPending,
  } = useQuery(enrollmentsListQuery(filter));

  const {
    data: sectionProgress,
    isPending: spPending,
    refetch,
  } = useQuery(sectionProgressAdminListQuery([lesson.id], cls?.id));

  useEffect(() => {
    //process enrollments
    if (!enrollments) return;
    if (!sectionProgress) return;

    enrollments.forEach((e) => {
      const sp = sectionProgress.get(e.id);
      const completed = sp?.filter((s) => s.complete);

      e.totalCompleted = completed ? completed.length : 0;
      e.progress = completed ? arrayToMap(completed, "sectionId") : null;
      e.sections = sections;
      if (!e.teamId) {
        e.expand.teamId = {
          id: "",
          name: "none",
        };
      }
    });
    setData([...enrollments]);
  }, [enrollments, sectionProgress]);

  if (error) return "An error has occurred: " + error.message;
  const columns = useMemo(() => USERS_COLUMNS, []);

  return (
    <div className="p-2 w-full m-auto max-w-9xl grid gap-4">

      <PaginationTable
        data={data}
        columns={columns}
        sortEnabled={true}
        isPending={isPending}
        columnFilters={[{ id: "role", value: [200] }]}
      />
    </div>
  );
}
