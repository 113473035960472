import { Header } from "@/components/format/Dialog";
import { ChartBar } from "lucide-react";
import { UnitProgressTable } from "@/lms/unit/UnitProgressTable";
import { UnitsResponse } from "@/lib/pb/types";
import { ClassFilter } from "../classes/ClassFilter";

import { ScrollArea } from "@/components/ui/scroll-area";

export function UnitProgressDialog ({ item }: { item: UnitsResponse }) {
  return (
    <div className="flex flex-col gap-2">
      <Header
        title={
          <div className="flex text-primary items-center gap-2">
            <ChartBar className="h-6 w-6 me-2 text-4xl" /> Unit Progress:  <div className="text-foreground text-2xl">{item.index}. {item.name}</div>
            <ClassFilter className="text-sm ms-10" />
          </div>
        }
      />
      <ScrollArea className="h-[90dvh]">
        <div className="flex flex-col ">
         <UnitProgressTable unit={item} />
        </div>
      </ScrollArea>
    </div>
  );
}
