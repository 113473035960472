import type { FormApi, useForm } from "@tanstack/react-form";
import { Button, ButtonProps } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Submitter } from "@/components/form";
import { AnyFunction } from "@/lib/types";
import { Send, Save } from "lucide-react";
import { Spinner } from "@/components/ui/spinner";
interface SubmitButtonsProps extends Omit<ButtonProps, "form"> {
  label?: React.ReactNode;
  onComplete?: AnyFunction;
  form?: FormApi<any, any>;
}

export function SubmitButton({
  label = "Submit",
  form,
  variant = "default",
  className,
  onClick,
  ...props
}: SubmitButtonsProps) {
  if (!form) return null;
  return (
    <Submitter
      form={form as ReturnType<typeof useForm>}
      children={([canSubmit, isSubmitting]) => (
        <Button
          onClick={onClick}
          className={cn(className)}
          name="submitButton"
          value="submit"
          type="submit"
          disabled={!canSubmit}
          variant={variant}
          {...props}
        >
          {isSubmitting ? <Spinner /> : label}
        </Button>
      )}
    />
  );
}

export function ElementSubmitButton({
  form,
  className,
  disabled,
  resubmit = false,
  type,
  ...props
}: {
  type: string;
  className?: string;
  form: FormApi<any, any>;
  disabled?: boolean;
  resubmit?: boolean;
}) {
  return (
    <SubmitButton
      form={form}
      variant="secondary"
      className={cn(
        "h-9 w-32  border border-transparent rounded-full",
        resubmit
          ? "border-muted-foreground bg-secondary hover:bg-secondary/80"
          : "border-primary bg-transparent hover:bg-primary text-primary hover:border-foreground hover:text-foreground",
        disabled
          ? "bg-background text-muted-foreground border-muted-foreground"
          : "",
        className,
      )}
      label={
        <div className="flex items-center gap-2">
          <Send className="h-4 w-4" />
          {type == "code-playground" ? (
            <div>{resubmit ? "Save" : "Save"}</div>
          ) : (
            <div>{resubmit ? "Resubmit" : "Submit"}</div>
          )}
        </div>
      }
      disabled={disabled}
      {...props}
    />
  );
}
//          label={submission ? "Resubmit" : "Submit"}
// className={
//   submission
//     ? "border-muted-foreground bg-secondary hover:bg-secondary/80"
//     : "border-success/50 bg-success/25 hover:bg-success/40"
// }

export function SaveSubmitButtons({
  save,
  form,
}: {
  save?: boolean;
  form: FormApi<any, any>;
}) {
  return (
    <Submitter
      form={form as ReturnType<typeof useForm>}
      children={([canSubmit, isSubmitting]) => (
        <div className="flex gap-2">
          {!save ? null : (
            <Button
              type="submit"
              onClick={() => {
                form.setFieldValue("submitButton", "save");
              }}
              disabled={!canSubmit}
            >
              {isSubmitting ? <Spinner /> : "Save"}
            </Button>
          )}
          <Button
            type="submit"
            onClick={() => {
              form.setFieldValue("submitButton", "submit");
            }}
            disabled={!canSubmit}
          >
            {isSubmitting ? <Spinner /> : "Submit"}
          </Button>
        </div>
      )}
    />
  );
}

export function ConfirmButtons({
  label = "Confirm",
  variant = "default",
  onComplete,
  form,
  onClick,
  ...props
}: SubmitButtonsProps) {
  return (
    <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
      <Button type="button" variant="ghost" onClick={onComplete}>
        Cancel
      </Button>
      {form ? (
        <SubmitButton variant={variant} form={form} label={label} {...props} />
      ) : null}
      {onClick ? (
        <Button variant={variant} onClick={onClick} {...props}>
          {label}
        </Button>
      ) : null}
    </div>
  );
}
